<template>
  <div class="loginButton">
    <img
      src="@/assets/img/google-sign-in.png"
      alt="Sign-in with Google"
      @click="doLogin"
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Vue from 'vue';
import ConfigHelper from "../services/configHelper";

export default {
  name: 'GoogleLogin',
  data() {
    return {
      redirectionUrl: null,
    };
  },
  async created() {
    document.querySelector('html').className = 'login-page';
    this.redirectionUrl = await ConfigHelper.getConfigValue('LOGIN_REDIRECTION_URL', true);
  },
  methods: {
    /**
     *  Launch the oAuth window and hopefully get a googleUser object back
     *  Data like token / email / etc is passed to the server app
     */
    doLogin() {
      if (!this.canLogin()) {
        Vue.prototype.addDialog('Sorry, you cannot log in to this system yet');
        return;
      }
      if (this.$route.query.nextUrl !== null && this.canNavigateTo(this.$route.query.nextUrl)) {
        this.setNextUrlState(this.$route.query.nextUrl.toString());
      } else {
        this.setNextUrlState(null);
      }
      window.location.href = this.redirectionUrl;
    },
    canNavigateTo(routeName) {
      return (
        routeName !== undefined &&
        routeName !== 'Login' &&
        routeName !== 'Logout' &&
        routeName !== 'NotFound'
      );
    },
    ...mapActions('app', ['setNextUrlState']),
  },
};
</script>

<style scoped>
  .loginButton img {
    cursor: pointer;
  }
</style>
