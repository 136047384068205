<template>
  <section class="hero is-fullheight">
    <div class="hero-body is-desktop is-centered columns">
      <div class="column is-one-quarter box has-text-centered">
        <img
          :src="getFrontEndSkinLogo()"
          class="margin-top-2-rem margin-bottom-2-rem"
          width="80%"
          :alt="getFrontEndSkinName()"
        >

        <div class="margin-bottom-4-rem">
          <h1 class="title is-4">
            {{ getFrontEndSkinName() }} Connector
          </h1>
          <h2 class="subtitle is-6">
            for Google Data Studio
          </h2>
        </div>

        <GoogleLogin
          v-if="canLogin()"
        />

        <div
          v-else
          class="margin-bottom-4-rem"
        >
          <h1 class="title is-4">
            Not available yet
          </h1>
          <h2 class="subtitle is-6">
            Please check back when we launch!
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GoogleLogin from '@/components/GoogleLogin';

export default {
  name: 'PageLogin',
  components: {
    GoogleLogin,
  },
  computed: {
    ...mapState('user', ['loggedIn']),
  },
  mounted() {
    if(!this.isBusinessUnitRaven()) {
      return;
    }
    // partnerstack snippet
    /* eslint-disable */
   (function() {
     var gs = document.createElement('script');
     gs.src = 'https://js.partnerstack.com/v1/';
     gs.type = 'text/javascript';
     gs.async = 'true';
     gs.onload = gs.onreadystatechange = function() {
       var rs = this.readyState;
       if (rs && rs != 'complete' && rs != 'loaded') return;
       try {
         growsumo._initialize('pk_x041Aw5KhmpIgiPX7wTN3PrYgdVJa3Oj');
         if (typeof(growsumoInit) === 'function') {
           growsumoInit();
         }
       } catch (e) {}
     };
     var s = document.getElementsByTagName('script')[0];
     s.parentNode.insertBefore(gs, s);
   })()
  },
  activated() {
    this.clearLoadingState();

    if (this.loggedIn) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    ...mapActions('app', ['clearLoadingState']),
  },
};
</script>
